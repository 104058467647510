<template>
  <div id="container" ref="page" v-loading.fullscreen.lock="fullscreenLoading" class="page">
    <div class="container">
      <el-timeline v-show="Object.keys(timeline).length" ref="timeline">
        <el-timeline-item v-for="(item, key, index) in timeline" :key="key" size="large" :timestamp="key" placement="top">
          <div class="box">
            <div class="grid" :class="'grid' + index">
              <div class="grid-sizer"></div>
              <div v-for="(file, i) in item" :key="i" class="grid-item" :class="{
                'grid-item-pdf': getMainFileType(file) == 'pdf',
                'grid-item-video': getMainFileType(file) == 'video',
                'grid-item-img': getMainFileType(file) == 'image',
                pdfPad: getMainFileType(file) == 'pdf' && !isPad
              }" @click="showEnlargeFile(file)">
                <div class="wrap">
                  <div>
                    <video v-if="getMainFileType(file) == 'video'" width="100%" controls="" name="media">
                      <source :src="file.url" type="audio/flac" />
                    </video>

                    <img v-else-if="getMainFileType(file) == 'image'" :src="file.url" alt="" />
                    <div v-else-if="getMainFileType(file) == 'pdf'">
                      <div v-if="isPad">
                        <p>pdf文件</p>
                        <p>文件名称：{{ file.name }}</p>
                      </div>

                      <div v-else>
                        <iframe :src="file.url" alt="" scrolling="no"></iframe>
                        <span class="pdf-action">
                          <span class="item-preview" @click="handlePdfPreview(file)">
                            <i class="el-icon-zoom-in"></i>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="info">
                    <span>{{ file.form && file.form.person }}</span><span v-if="file.form && file.form.direction">方向：{{
                      file.form && file.form.direction }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-timeline-item>
      </el-timeline>
      <el-empty v-show="!Object.keys(timeline).length" description="暂无文件" />
    </div>
    <div class="footer" flex>
      <el-button class="flex_1" style="line-height: 40px" @click="handleCancel">取消</el-button>
    </div>

    <el-dialog title="预览" top="5vh" custom-class="file-dialog" :visible.sync="fileDialog.show" width="80%">
      <iframe ref="fileIframe" style="height:80vh" :src="`${host}/pdf/web/viewer.html?file=${fileDialog.url}`"
        frameborder="0"></iframe>
    </el-dialog>
  </div>
</template>

<script>
import Masonry from 'masonry-layout'
import imagesLoaded from 'imagesloaded'
import { getEvidenceList } from '@/api/evidence/index.js'
export default {
  data() {
    return {
      timeline: [],
      archivesId: null,
      token:
        'eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6ImUzN2FjMWMyLTY4NzItNDkwMS1iMzQ2LTdkN2I3OWQ1MDYyMCJ9.hrCC4hgfq9m_yNVzGKHiMY8Rt4JSBfiJTsCs_shb5qdsXtLTCiDCJDpAQLSi4382FOdJg_l7A23fz2YKy7euhg',
      fileSrc: '',
      fileDialog: {
        show: false,
        url: ''
      },
      fullscreenLoading: false
    }
  },
  computed: {
    host() {
      return location.origin
    },
    headers() {
      return {
        Authorization: 'Bearer ' + this.token
      }
    },
    isPad() {
      return window.innerWidth <= 1200
    },
    getMainFileType() {
      return (file) => {
        if (file.type == 'application/pdf' || file.type == 'pdf') return 'pdf'
        if (file.type && file.type.indexOf('/') != '-1') return file.type.split('/')[0]
        if (file.url && file.url.indexOf('.') != '-1') {
          const extension = file.url.split('.').pop().toLowerCase();
          const fileTypeMapping = {
            jpg: 'image',
            jpeg: 'image',
            png: 'image',
            gif: 'image',
            mp4: 'video',
            mov: 'video',
            avi: 'video',
            wmv: 'video',
          };
          const fileType = fileTypeMapping[extension] || '未知类型';
          return fileType;
        }
        if (!file.type) return '未知类型'
      }
    },
  },
  watch: {
    '$route.query': {
      async handler(newVal) {
        console.log(newVal);
        this.token = newVal.token
        // this.archivesId = newVal.archivesId
      },
      immediate: true
    }
  },
  created() { },
  async mounted() {
    this.timeline = await this.getNoticeList()
    if (!Object.keys(this.timeline).length) {
      this.fullscreenLoading = false
      return
    }
    // const that = this
    this.$nextTick(() => {
      Object.keys(this.timeline).forEach((item, index) => {
        // grid.()
        // imagesLoaded('.grid', {}, function() {
        //   console.log('图片加载完毕')
        //   var msnry = new Masonry('.grid', {
        //     columnWidth: 80,
        //     itemSelector: '.grid-item'
        //   })
        // })

        // imagesLoaded(document.querySelector('#container'), function(instance) {
        //   console.log('all images are loaded')
        //   that.fullscreenLoading = false
        //   var msnry = new Masonry('.grid' + index, {
        //     columnWidth: '.grid-sizer',
        //     itemSelector: '.grid-item',
        //     percentPosition: true
        //   })
        // })

        var imgLoad = imagesLoaded(document.querySelector('#container'))
        imgLoad.on('progress', function (instance, image) {
          new Masonry('.grid' + index, {
            columnWidth: '.grid-sizer',
            itemSelector: '.grid-item',
            percentPosition: true
          })
        })
      })
    })
  },
  methods: {
    async getNoticeList() {
      console.log(this.$route.query);
      let res = await getEvidenceList({ noticeId: this.$route.query.noticeId })
      if (res.code == 200) {
        const timeline = {}
        res.data.forEach((file) => {
          const time = this.parseTime(
            file.time || file.form.creatTime,
            '{y}年{m}月{d}日'
          )
          if (timeline[time]) {
            timeline[time].push(file)
          } else {
            timeline[time] = [file]
          }
        })
        console.log(timeline);
        return timeline
      } else {
        return {}
      }

    },
    showEnlargeFile(file) {
      if (this.isPad) {
        //  this.fileDialog.show = true
        // this.fileDialog.url = file.url
        if (file.type == 'img') {
          window.uni.postMessage({
            data: { action: 'previewImage', imgList: [file.url] }
          })
        } else if (file.type == 'pdf') {
          this.fileDialog.show = true
          this.fileDialog.url = file.url
        }
      } else {
        window.open(file.url)
      }

      // if (file.type == 'pdf' && this.isPad) {
      //   this.fileDialog.show = true
      //   this.fileDialog.url = file.url
      //   this.$confirm('此操作将跳转到第三方打开文件, 是否继续?', '提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     type: 'warning'
      //   })
      //     .then(() => {
      //       window.uni.postMessage({
      //         data: { action: 'openDoc', url: file.url }
      //       })
      //     })
      //     .catch(() => {})
      // } else if (file.type == 'img' && this.isPad) {
      //   window.uni.postMessage({
      //     data: { action: 'previewImage', imgList: [file.url] }
      //   })
      // } else {
      //   window.open(file.url)
      // }
    },
    handlePdfPreview(file) {
      // this.fileDialog.show = true
      // this.fileDialog.url = file.url
    },
    submitForm() {
      this.backUniApp('success')
      window.parent.postMessage('success', '*')
    },
    handleCancel() {
      this.backUniApp('cancel')
      window.parent.postMessage('cancel', '*')
      // window.history.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  background-color: #fff;

  .container {
    flex: 1;
    height: calc(100vh - 66px);
    overflow: auto;
    padding: 10px 12px;
    min-width: 50%;
  }
}

.grid {
  &::after {
    content: '';
    display: block;
    clear: both;
  }

  .grid-sizer,
  .grid-item {
    width: 20%;
  }

  .grid-item {
    color: #fff;
    font-size: 30px;
    // margin: 0 5px 10px 0;
    float: left;
    padding: 0 5px 5px;

    // &.grid-item-img {
    //   width: 20%;
    // }
    &.grid-item-video {
      width: 40%;
    }

    &.grid-item-pdf {
      font-size: 12px;

      & {
        width: 20%;
        padding: 0 5px 5px;
        background-color: transparent;
        overflow: hidden;

        .wrap {
          background-color: gray;
          border-radius: 10px;
          position: relative;
          width: 100%;
          padding: 14px 20px;
          color: #fff;

          iframe {
            width: 108%;
          }

          &:hover {
            .pdf-action {
              opacity: 1;
            }
          }

          .pdf-action {
            background-color: rgba(0, 0, 0, .5);
            ;
            width: 100%;
            height: 100%;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            font-size: 20px;
            display: flex;
            justify-content: center;
            align-items: center;

            .item-preview {
              cursor: pointer;
            }
          }
        }
      }
    }

    img,
    video,
    iframe {
      display: block;
      width: 100%;
      background-color: gray;
      border-radius: 10px;
      // height: 100%;
    }

    .wrap {
      margin-bottom: 5px;
    }

    .info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #1e2023a6;
      font-size: 12px;
    }
  }
}

::v-deep .el-dialog {
  &.file-dialog {

    // height: 500px;
    // width: 100%;
    // height: 100vh;
    .el-dialog__body {
      height: 100%;
      padding: 0;
    }

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
